<template>
  <div>
    <page-header title="Sales Overview" />
    <v-row>
      <!-- LEFT COLUMN -->
      <v-col cols="8">
        <v-card class="pa-4">
          <div class="d-flex align-center">
            <img src="/img/sales-card.svg" />
            <div style="color: #db8518" class="ml-2 fw-700">SALES NUMBERS</div>
            <v-spacer />
            <help-tooltip v-if="!totalSales">
              Data will not be available until tracking is configured.
            </help-tooltip>
          </div>
          <div class="d-flex align-center my-8">
            <div style="color: #095473" class="fs-14 fw-800">Total Sales</div>
            <div style="color: #db8518" class="ml-3 fs-20 fw-700">
              ${{ totalSales | numberFormatter }}
            </div>
            <v-spacer />
            <div style="color: #095473" class="fs-12 fw-800">Average Sale</div>
            <div style="color: #db8518" class="ml-2 fs-16 fw-700">
              ${{ (totalSales / totalOrders) | numberFormatter }}
            </div>
            <div style="color: #095473" class="ml-2 fs-12 fw-800">in</div>
            <div style="color: #db8518" class="ml-2 fs-16 fw-700">
              {{ totalOrders | numberFormatter }}
            </div>
            <div style="color: #095473" class="ml-2 fs-12 fw-800">Orders</div>
          </div>
          <div id="salesChartContainer"></div>
          <div class="mt-8 ml-8">
            <div style="color: #095473" class="fs-12 fw-800">Frequent Buyers</div>
            <div
              class="d-flex align-center mt-2"
              v-for="customer in frequentBuyers"
              :key="customer._id"
            >
              <div style="color: #3e89a8" class="fs-14 fw-700">
                {{ customer.firstName }} {{ customer.lastName }}
              </div>
              <div style="color: #979797" class="ml-2 fs-10 fw-700">
                {{ customer.address.state }},
                {{ customer.address.country }}
              </div>
              <div style="color: #095473" class="ml-2 fs-10 fw-700" v-if="false">Follower at</div>
              <img
                class="ml-2"
                src="https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Instagram-512.png"
                width="16"
                v-if="false"
              />
              <img
                class="ml-2"
                src="https://cdn.iconscout.com/icon/free/png-256/facebook-224-498412.png"
                width="16"
                v-if="false"
              />
            </div>
          </div>
          <div class="mt-2 d-flex justify-end">
            <a style="color: #3e89a8" class="fs-12 fw-600" @click="$router.replace('/products')">
              Go to Product Analysis Page
            </a>
          </div>
        </v-card>
      </v-col>
      <!-- RIGHT COLUMN -->
      <v-col cols="4">
        <div class="d-flex flex-column fill-height">
          <v-card class="flex-1 d-flex flex-column mb-4 pa-4">
            <div class="d-flex align-center">
              <img src="/img/sales-card.svg" />
              <div style="color: #db8518" class="ml-2 fw-700">BUYER'S SOURCE</div>
              <v-spacer />
              <help-tooltip v-if="!totalVisitors">
                Data will not be available until tracking is configured.
              </help-tooltip>
            </div>
            <div id="buyerSourceChartContainer"></div>
            <v-spacer />
            <div class="d-flex justify-end" v-if="false">
              <div style="color: #3e89a8" class="fs-12 fw-600">Go to Buyers Page</div>
            </div>
          </v-card>
          <v-card class="flex-1 pa-4">
            <div class="d-flex align-center">
              <img src="/img/sales-card.svg" />
              <div style="color: #db8518" class="ml-2 fw-700">VISITORS AND BUYERS</div>
              <v-spacer />
              <help-tooltip v-if="!totalVisitors">
                Data will not be available until tracking is configured.
              </help-tooltip>
            </div>
            <div class="d-flex align-center mt-2">
              <div style="color: #095473" class="fs-12 fw-800">Total Visitors</div>
              <div style="color: #5394b0" class="ml-2 fs-20 fw-600">
                {{ totalVisitors | numberFormatter }}
              </div>
              <v-spacer />
              <div style="color: #095473" class="fs-12 fw-800">Total Buyers</div>
              <div style="color: #5394b0" class="ml-2 fs-20 fw-600">
                {{ totalBuyers | numberFormatter }}
              </div>
            </div>
            <div id="trafficChartContainer"></div>
            <div class="d-flex justify-end" v-if="false">
              <div style="color: #3e89a8" class="fs-12 fw-600">Go to Visitors Page</div>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HelpTooltip from '@/components/App/HelpTooltip.vue';
import PageHeader from '@/components/App/PageHeader.vue';
import SalesService from '@/services/SalesService';

HighchartsMore(Highcharts);

export default {
  name: 'DashboardPage',
  mixins: [SalesService],
  components: {
    HelpTooltip,
    PageHeader,
  },
  data: () => ({
    totalSales: 0,
    totalOrders: 0,
    totalVisitors: 0,
    totalBuyers: 0,
    frequentBuyers: [],
    salesHistory: {},
    buyerSourceHistory: {},
    visitorsHistory: {},
    buyersHistory: {},
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.fetchSalesOverview();
      this.fetchSalesHistory();
      this.fetchBuyerSourceHistory();
      this.fetchTrafficHistory();
    },
    async fetchSalesOverview() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getSalesOverview(params);
      this.totalSales = data.totalSales;
      this.totalOrders = data.totalOrders;
      this.frequentBuyers = data.frequentBuyers;
    },
    async fetchSalesHistory() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getSalesHistory(params);
      this.salesHistory = data.reduce((history, item) => {
        history[item.date] = item.price;
        return history;
      }, {});
      this.updateSalesChart();
    },
    async fetchBuyerSourceHistory() {
      const params = { dateRange: this.fullDateRange };
      const data = await this.getBuyerSourceHistory(params);
      this.buyerSourceHistory = data.reduce((history, item) => {
        history[item.date] = item;
        return history;
      }, {});
      this.updateBuyerSourceChart();
    },
    async fetchTrafficHistory() {
      const params = { dateRange: this.fullDateRange };
      const { visitorsData, buyersData } = await this.getTrafficHistory(params);
      this.totalVisitors = visitorsData.reduce((total, item) => total + item.visitors, 0);
      this.totalBuyers = buyersData.reduce((total, item) => total + item.buyers, 0);
      this.visitorsHistory = visitorsData.reduce((history, item) => {
        history[item.date] = item.visitors;
        return history;
      }, {});
      this.buyersHistory = buyersData.reduce((history, item) => {
        history[item.date] = item.buyers;
        return history;
      }, {});
      this.updateTrafficChart();
    },
    updateSalesChart() {
      const values = this.chartDateRange.map(date => this.salesHistory[date] || 0);
      Highcharts.chart('salesChartContainer', {
        chart: {
          type: 'areaspline',
          height: 300,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
        tooltip: {
          borderWidth: 0,
          backgroundColor: '#FFA521',
          shadow: false,
          style: {
            fontSize: '16px',
            color: '#FFFFFF',
          },
          formatter() {
            return `
              ${this.x}<br />
              Sales: $${this.y}
            `;
          },
        },
        xAxis: {
          visible: false,
          categories: this.chartDateRange,
        },
        yAxis: {
          title: {
            text: undefined,
          },
          labels: {
            formatter() {
              return `$ ${Math.floor(this.value / 100) * 100}`;
            },
          },
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [
          {
            name: 'Price',
            data: values,
            color: '#FFA521',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, '#FFA521'],
                [1, Highcharts.color('#FFA521').setOpacity(0).get('rgba')],
              ],
            },
          },
        ],
      });
    },
    updateBuyerSourceChart() {
      const self = this;
      const socialChannelData = this.chartDateRange.map(
        date => (this.buyerSourceHistory[date] && this.buyerSourceHistory[date].Social) || 0,
      );
      const directChannelData = this.chartDateRange.map(
        date => (this.buyerSourceHistory[date] && this.buyerSourceHistory[date].Direct) || 0,
      );
      Highcharts.chart('buyerSourceChartContainer', {
        chart: {
          type: 'areaspline',
          height: 180,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
        legend: {
          align: 'left',
        },
        tooltip: {
          borderWidth: 0,
          backgroundColor: '#FFA521',
          shadow: false,
          style: {
            fontSize: '16px',
            color: '#FFFFFF',
          },
          formatter() {
            const data = self.buyerSourceHistory[this.x];
            return `
              ${this.x}<br />
              Social: ${(data && data.Social) || 0}
              <br />
              Direct: ${(data && data.Direct) || 0}
            `;
          },
        },
        xAxis: {
          visible: false,
          categories: this.chartDateRange,
        },
        yAxis: {
          visible: false,
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
          },
        },
        series: [
          {
            name: 'Social',
            data: socialChannelData,
            color: '#FFA521',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, '#FFA521'],
                [1, Highcharts.color('#FFA521').setOpacity(0).get('rgba')],
              ],
            },
          },
          {
            name: 'Direct',
            data: directChannelData,
            color: '#3E89A8',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, '#3E89A8'],
                [1, Highcharts.color('#3E89A8').setOpacity(0).get('rgba')],
              ],
            },
          },
        ],
      });
    },
    updateTrafficChart() {
      const self = this;
      const visitors = this.chartDateRange.map(date => this.visitorsHistory[date] || 0);
      const buyers = this.chartDateRange.map(date => this.buyersHistory[date] || 0);
      Highcharts.chart('trafficChartContainer', {
        chart: {
          type: 'column',
          height: 150,
          spacing: 0,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
        legend: {
          align: 'left',
        },
        xAxis: {
          visible: false,
          minPadding: 0,
          maxPadding: 0,
          categories: this.chartDateRange,
        },
        yAxis: {
          visible: false,
          min: 0,
          maxPadding: 0,
        },
        tooltip: {
          borderWidth: 0,
          backgroundColor: '#FFA521',
          shadow: false,
          style: {
            fontSize: '16px',
            color: '#FFFFFF',
          },
          formatter() {
            return `
              ${this.x}<br />
              Visitors: ${self.visitorsHistory[this.x] || 0}<br />
              Buyers: ${self.buyersHistory[this.x] || 0}
            `;
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          },
          series: {
            groupPadding: 0,
          },
        },
        series: [
          {
            name: 'Visitors',
            data: visitors,
            stack: 'group',
            color: '#FFA521',
            borderRadius: 3,
            borderWidth: 0,
            pointWidth: 6,
          },
          {
            name: 'Buyers',
            data: buyers,
            stack: 'group',
            color: '#3E89A8',
            borderRadius: 3,
            borderWidth: 0,
            pointWidth: 6,
          },
        ],
      });
    },
  },
  watch: {
    dateRange(newValue, oldValue) {
      if (oldValue.length === 1 && newValue.length === 2) {
        this.refresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
