export default {
  methods: {
    getSalesOverview(params) {
      return $http.get('/sales/sales-overview', { params });
    },
    getSalesHistory(params) {
      return $http.get('/sales/sales-history', { params });
    },
    getBuyerSourceHistory(params) {
      return $http.get('/sales/buyer-source-history', { params });
    },
    getTrafficHistory(params) {
      return $http.get('/sales/traffic-history', { params });
    },
  },
};
